<template>
  <div class="assets">
    <div class="header">
      <van-nav-bar :title="$t('assets.title')"> </van-nav-bar>
    </div>
    <div class="maincontent skiptranslate">
      <!-- 背景区域 -->
      <div class="total">
        <div class="head">
          <van-row>
            <span style="color: white; font-size: 15px; display: flex;"
              >{{ $t('assets.zassets') }}
              <span
                style="padding-top: 5px; margin-left: 5px;"
                @click="eyeevent"
              >
                <van-icon v-if="eye" name="eye" style="font-size: 15px;" />
                <van-icon v-else name="closed-eye" style="font-size: 15px;" />
              </span>
            </span>
          </van-row>
          <van-row>
            <span> UID:{{ userinfo.username }} </span>
          </van-row>
          <van-row>
            <span
              v-if="eye"
              style="width: 100%; display: flex; justify-content: center;"
            >
              <h1>
                {{ balance.usdt }}(USDT)
                <!-- {{ balance.btc }}(BTC) -->
                <!-- <span> ≈ {{ balance.usd }}(USD) </span> -->
              </h1>
            </span>
            <span
              v-else
              style="width: 100%; display: flex; justify-content: center;"
              ><h1>* * * * * *</h1></span
            >
          </van-row>
          <van-row class="head-icon">
            <van-col span="6" @click="recharge">
              <div>
                <van-image
                  width="2rem"
                  height="2rem"
                  :src="require('../../assets/img/assets-cz.png')"
                />
              </div>
            </van-col>
            <van-col span="6" @click="withdraw">
              <div>
                <van-image
                  width="2rem"
                  height="2rem"
                  :src="require('../../assets/img/assets-tb.png')"
                />
              </div>
            </van-col>
            <van-col span="6" @click="serverurls">
              <div>
                <van-image
                  width="2rem"
                  height="2rem"
                  :src="require('../../assets/img/kefu.png')"
                />
              </div>
            </van-col>
            <van-col span="6"  @click="$router.push('/trade/shopping/trading')">
               <div>
                 <van-image
                   width="2rem"
                   height="2rem"
                   :src="require('../../assets/img/assets-dh.png')"
                 />
               </div>
             </van-col>
          </van-row>
          <van-row class="head-title ">
            <van-col span="6" @click="recharge">
              <span>{{ $t('assets.charge') }}</span>
            </van-col>
            <van-col span="6" @click="withdraw">
              <span>{{ $t('assets.withdraw') }}</span>
            </van-col>
            <van-col span="6" @click="serverurls">
              <span>{{ $t('login.serverurl') }}</span>
            </van-col>
           <van-col span="6" @click="$router.push('/trade/shopping/trading')" >
              <span>{{ $t('assets.duihuan') }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 今日统计区域 -->
      <van-row class="today">
        <van-row class="today-left">
          <h3>{{ $t('assets.tprofit') }}</h3>
          <van-row class="today-left-span">
            <span style="color: rgb(76, 180, 164);">
              {{ tongji['profit'] }}
            </span>
            USDT
          </van-row>
        </van-row>
        <van-row class="today-right">
          <van-row
            style="background-color: rgb(248, 248, 248); margin-bottom: 3%; height: 47%; border-radius: 7.5px;"
          >
            <h5 style="padding: 0">{{ $t('assets.tamount') }}</h5>
            <van-row class="today-right-span">
              <span>{{ tongji['price'] }}</span>
            </van-row>
          </van-row>
          <van-row
            style="background-color: rgb(248, 248, 248); height: 47%; border-radius: 7.5px;"
          >
            <h5 style="padding: 0">{{ $t('assets.freeze') }}</h5>
            <van-row class="today-right-span">
              <span> {{ balance.freeze }}</span>
            </van-row>
          </van-row>
        </van-row>
      </van-row>
      <!-- 信用分 -->
      <div v-if="creditValueStatus">
        <div class="main-head">
          <h3>{{ $t('assets.credit') }}</h3>
        </div>
        <canvas id="main"></canvas>
        <div class="main-bottom">
          <span> {{ $t('assets.high') }}100 </span>
        </div>
      </div>
      <!-- <div
        style="background-color: rgb(248, 248, 248); height: 10px; width: 100%;"
      ></div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      eye: true,
      // canvas
      context: {},
      score: 0,
      userinfo: {},
      balance: {},
      tongji: [],
      creditValueStatus:false
    }
  },
  created() {
    this.getdata()
    this.getinfo()
    this.getCreditValueStatus()

  },
  mounted() {},
  methods: {
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get('/home/home/index')
      if (data) {
        if (data.code === 200) {
           this.serverurl = data.data.serverurl
        }
      }
    },
    // 信用分画图
    createcanvas() {
      this.$nextTick(() => {
        const canvas = document.querySelector('#main')
        this.context = canvas.getContext('2d')
        this.context.rotate(Math.PI / 2)
        // 刻度
        for (let i = 0; i <= 20; i++) {
          this.context.save()
          this.context.lineWidth = 3
          if (i <= this.score / 5) {
            this.context.strokeStyle = '#0055ff'
          } else {
            this.context.strokeStyle = '#ccc'
          }
          const reg = i * (180 / 20)
          const pai = Math.PI / 180
          this.context.translate(130, -150)
          this.context.rotate(reg * pai)
          this.context.beginPath()
          this.context.moveTo(0, 120)
          this.context.lineTo(0, 100)
          this.context.stroke()
          this.context.restore()
        }
        // 信用分
        this.context.rotate(-Math.PI / 2)
        this.context.font = '700 46px Arial'
        if (this.score === 100) {
          this.context.fillText(100, 110, 140)
        } else {
          this.context.fillText(this.score, 130, 140)
        }
        this.context.closePath()
      })
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          this.score = +data.data.userinfo.credit_score

          this.userinfo = data.data.userinfo
          this.balance = data.data.balance
          this.tongji = data.data.tongji
          this.createcanvas()
        }
      }
    },
    async getCreditValueStatus() {
      const { data } = await this.$http.get('/home/index/creditValueStatus')
      if (data) {
        if (data.code === 200) {
          if(data.data.status === "1") {
            this.creditValueStatus = false
          }else {
            this.creditValueStatus = true
          }

        }
      }
    },
    eyeevent() {
      this.eye = !this.eye
    },
    // 充值
    recharge() {
      this.$router.push('/assets/recharge')
    },
    // 提币
    withdraw() {
      this.$router.push('/assets/withdraw')
    },
    // 划转
    transfer() {
      this.$router.push('/assets/transfer')
    },
    // 客服
    serverurls() {
        window.location.href = this.serverurl
    },
    // 兑换
    conversion() {
      this.$router.push('/assets/conversion')
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding: 70px 0 50px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  // 背景区域
  .total {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.53333rem;
    .head {
      width: 95%;
      padding: 0.8rem 0.8rem 0.8rem 1.2rem;
      color: #fff;
      border-radius: 0.53333rem;
      line-height: 1.86667rem;
      text-align: left;
      background-color: #0055ff;
      background-size: 100% 100%;
      span {
        font-size: 0.8rem;
      }
      h1 {
        font-size: 1.4rem;
        span {
          font-size: 1rem;
        }
      }
      .head-icon {
        border-top: 0.02667rem solid #232b40;
        padding-top: 0.93333rem;
        margin-top: 0.8rem;
      }
      .head-icon div,
      .head-title div {
        display: flex;
        justify-content: center;
      }
      .head-title span {
        font-size: 0.93333rem;
        color: #fff;
      }
    }
  }
  // 今日统计
  .today {
    width: 95%;
    height: 9.86667rem;
    margin-left: 2.5%;
    display: flex;
    color: #212121;
    .today-left {
      width: 50%;
      height: 100%;
      background-color: #f8f8f8;
      border-radius: 0.4rem;
      h3 {
        height: 20%;
        text-align: left;
        padding: 0.4rem;
        font-size: 0.90667rem;
      }
      .today-left-span {
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 700;
          font-size: 1.33333rem;
          margin-right: 0.21333rem;
        }
      }
    }
    .today-right {
      width: 48%;
      margin-left: 2%;
      height: 100%;
      border-radius: 0.4rem;
      h5 {
        text-align: left;
        padding: 0.4rem;
        font-size: 0.90667rem;
      }
      .today-right-span {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 700;
          font-size: 1.33333rem;
          margin-right: 0.21333rem;
          color: #212121;
          margin-top: 0.26667rem;
        }
      }
    }
  }
  // 信用分
  .main-head {
    background-color: #fff;
    padding-top: 0.53333rem;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 0.53333rem;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    color: #000;
    h3 {
      text-align: left;
      padding-left: 0.4rem;
      font-size: 0.90667rem;
    }
  }
  .main {
    height: 9rem;
    background-color: #fff;
    width: 95%;
    margin-left: 2.5%;
    height: 9rem;
  }
  .main-bottom {
    background-color: #fff;
    width: 95%;
    margin-inside: 2%;
    margin-left: 2.5%;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    margin-bottom: 0.53333rem;
    span {
      display: block;
      padding: 0 2.5% 0.53333rem 0;
      text-align: right;
      margin-left: 0.4rem;
      font-size: 0.66667rem;
      color: #0055ff;
    }
  }
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
  }
  /deep/.van-tab--active {
    color: #0055ff;
  }
  //比比资产
  .currency-Increase {
    color: #212121;
    padding-top: 1.5rem;
    padding-bottom: 0.8rem;
    font-size: 0.93333rem;
    display: flex;
    align-items: center;
    line-height: 1.73333rem;
    border-bottom: 0.02667rem solid #fff;
    margin-bottom: 0.26667rem;
  }
}
</style>
